import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59')
];

export const server_loads = [0,7];

export const dictionary = {
		"/": [14],
		"/(authenticated)/admin": [15,[2,4],[3]],
		"/(authenticated)/admin/calendar": [35,[2,4,7],[3,,8]],
		"/(authenticated)/admin/calendar/overview": [~36,[2,4,7],[3,,8,9]],
		"/(authenticated)/admin/(overviews)/courses/form/[[id]]": [17,[2,4,5],[3]],
		"/(authenticated)/admin/(overviews)/courses/[page]": [~16,[2,4,5],[3]],
		"/(authenticated)/admin/(overviews)/google": [~18,[2,4,5,6],[3]],
		"/(authenticated)/admin/(overviews)/google/sync": [~19,[2,4,5,6],[3]],
		"/(authenticated)/admin/(overviews)/holidays/create": [21,[2,4,5],[3]],
		"/(authenticated)/admin/(overviews)/holidays/edit/[id]": [22,[2,4,5],[3]],
		"/(authenticated)/admin/(overviews)/holidays/[page]": [20,[2,4,5],[3]],
		"/(authenticated)/admin/(overviews)/lessons/create": [~24,[2,4,5],[3]],
		"/(authenticated)/admin/(overviews)/lessons/form/[[id]]": [25,[2,4,5],[3]],
		"/(authenticated)/admin/(overviews)/lessons/[page]": [23,[2,4,5],[3]],
		"/(authenticated)/admin/(overviews)/locations/form/[[id]]": [27,[2,4,5],[3]],
		"/(authenticated)/admin/(overviews)/locations/[page]": [26,[2,4,5],[3]],
		"/(authenticated)/admin/(overviews)/query": [28,[2,4,5],[3]],
		"/(authenticated)/admin/stats": [~37,[2,4],[3]],
		"/(authenticated)/admin/(overviews)/studentMetas/form/[[id]]": [30,[2,4,5],[3]],
		"/(authenticated)/admin/(overviews)/studentMetas/import": [31,[2,4,5],[3]],
		"/(authenticated)/admin/(overviews)/studentMetas/[page]": [~29,[2,4,5],[3]],
		"/(authenticated)/admin/(overviews)/teacherMetas/form/[[id]]": [~33,[2,4,5],[3]],
		"/(authenticated)/admin/(overviews)/teacherMetas/[page]": [~32,[2,4,5],[3]],
		"/(authenticated)/admin/(overviews)/times": [~34,[2,4,5],[3]],
		"/(authenticated)/check": [38,[2],[3]],
		"/(public)/forgot-password": [~55,[13]],
		"/(public)/login": [~56,[13]],
		"/oauth": [~59],
		"/(public)/setPassword": [~57,[13]],
		"/(authenticated)/sign-out": [~39,[2],[3]],
		"/(public)/signUp": [~58,[13]],
		"/(authenticated)/student/calendar": [40,[2,10],[3]],
		"/(authenticated)/student/google": [41,[2,10],[3]],
		"/(authenticated)/student/lessons": [42,[2,10],[3]],
		"/(authenticated)/student/lessons/register/[courseOrCourseSubId]/[type]/select/[lessonGroupId]": [44,[2,10],[3]],
		"/(authenticated)/student/lessons/register/[courseOrCourseSubId]/[type]/[lessonId]/[[lessonGroupId]]": [43,[2,10],[3]],
		"/(authenticated)/student/privateLessons": [45,[2,10],[3]],
		"/(authenticated)/student/privateLessons/[lessonId]/[courseOrCourseSub]/[courseOrCourseSubId]": [46,[2,10],[3]],
		"/(authenticated)/student/privateLessons/[lessonId]/[courseOrCourseSub]/[courseOrCourseSubId]/[lessonTeacherMetaId]/[teacherMetaId]": [47,[2,10],[3,,11]],
		"/(authenticated)/student/reservations": [48,[2,10],[3]],
		"/(authenticated)/student/reservations/[id]": [49,[2,10],[3]],
		"/(authenticated)/teacher/calendar": [50,[2,12],[3]],
		"/(authenticated)/teacher/google": [51,[2,12],[3]],
		"/(authenticated)/teacher/google/start": [52,[2,12],[3]],
		"/(authenticated)/teacher/homework": [53,[2,12],[3]],
		"/(authenticated)/teacher/times": [~54,[2,12],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';